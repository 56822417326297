<template>
  <div>
    <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5" />
    <span class="leading-none font-medium">Featured Image</span>

    <file-pond
      name="test"
      ref="pond"
      label-idle="Drop image here..."
      :allow-multiple="false"
      accepted-file-types="image/jpeg, image/png"
      instant-upload="false"
      :files="preview_image"
      @addfile="handleFileAdd"/>
    <span class="text-danger text-sm" v-show="!!validation.images">
    {{ validation.images? validation.images[0]: '' }}
    </span>

    <vs-input
      class="w-full mt-4"
      label="Alt Text"
      :danger="!!validation.alt"
      :danger-text="validation.alt?validation.alt[0]:''"
      :value="form.alt"
      @input="updateForm({key: 'alt', value: $event})" />
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
// Import FilePond
import vueFilePond from 'vue-filepond';

// Import plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';

// Import styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
const FilePond = vueFilePond( FilePondPluginFileValidateType, FilePondPluginImagePreview );

export default {
  components: {
    FilePond
  },
  computed: {
    ...mapGetters({
      form: 'cms/getFormObj',
      images: 'cms/getPageImages',
      preview_image: 'cms/previewImage',
      validation: 'validation/getValidationErrors',
    })
  },
  methods: {
    ...mapActions('cms', [
      'updateForm'
    ]),
    handleFileAdd(image_name, files) {
      this.updateForm({key: 'images', value: files.file})
    },
  }
}
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
