<template>
  <div id="page-user-list">
      <form method="POST" enctype="multipart/form-data"  @submit.prevent="submitForm">
       <div class="vx-row mb-6">
         <div class="vx-col w-full md:w-2/3">
           <div class="vx-card p-6 mb-6">
             <PageForm></PageForm>

           </div>
           <div class="vx-card p-6 mb-6">
             <SeoInfo></SeoInfo>
           </div>
         </div>
         <div class="vx-col w-full md:w-1/3">
           <div class="vx-card p-6 mb-6">
             <PageImage></PageImage>
           </div>
         </div>
         <!-- Save & Reset Button -->
         <div class="vx-card p-4 mb-4">

           <div class="flex flex-wrap items-center justify-end">
             <vs-button button="submit" class="ml-auto" >Save Changes</vs-button>
           </div>
         </div>
       </div>
      </form>
	</div>
</template>

<script>
import vSelect from 'vue-select'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import PageImage from './common/PageImage';
import {storeImage, updateImage} from '@/services/image';


import PageForm from './common/PageForm';
import SeoInfo from './common/SeoInfo';
import {BLOG, CMS_PAGE} from '@/constant/entity-identifier';
import loaderMixin from '@/mixins/loaderMixin';

export default {
  components: {
    vSelect,
    PageForm,
    SeoInfo,
    PageImage,
  },
  mixins: [loaderMixin],
  computed: {
    ...mapGetters({
        form: 'cms/getFormObj',
        pageImages: 'cms/getPageImages',
        alt: 'cms/getPageAlt',
    }),
    getPageId() {
        return this.$route.params.pageId;
    },
  },
  created() {
    this.clearForm();
    this.clearValidationErrors();
     this.openLoading();
     this.fetchAndSetPageById(this.getPageId).then(() => {
       this.closeLoading();
       // eslint-disable-next-line no-unused-vars
     }).catch((r) => this.closeLoading());
  },
  methods: {
    ...mapActions({
      updatePageAction: 'cms/updatePageAction',
      fetchAndSetPageById: 'cms/fetchAndSetPageById',
      clearForm: 'cms/clearForm',
      clearValidationErrors: 'validation/clearValidationErrors',
    }),
    ...mapMutations({
      clearForm: 'cms/CLEAR_FORM'
    }),
    prepareForm(data, type, method) {
      let formData = new FormData();
      formData.append("_method", method);
      formData.append("entity_id", data.id);
      formData.append("entity", type);
      (data.images !== null) ? formData.append("file_ids[]", data.images[0].id) : '';
      formData.append("images[]", this.pageImages[0]);
      formData.append("alt[]", this.alt);
      return formData;
    },
    submitForm() {
     this.openLoading();
      this.updatePageAction({
        pageId: this.getPageId,
        data: this.form
      }).then(async response => {
        if(response.status === 202) {
          if(this.pageImages.length > 0) {
            if(response.data.data.images){
              await updateImage(response.data.data.images[0].id, this.prepareForm(response.data.data, CMS_PAGE, 'POST'));
            }else{
              // eslint-disable-next-line no-undef
              await storeImage(this.prepareForm(response.data.data, CMS_PAGE, 'POST'));
            }
            this.closeLoading();
          }
          this.clearForm();
       this.closeLoading();
        this.$vs.notify({
            text: 'Page updated successfully.',
            title: 'Page Updated',
            color: 'success',
            position: 'top-right'
        });
        await this.$router.push('/pages');}
        // eslint-disable-next-line no-unused-vars
      }).catch((r) => this.$router.push('/pages'));
    },
  },
}
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
