<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5" />
        <span class="leading-none font-medium">Page Details</span>
      </div>
      <div class="vx-col w-full mt-4 ">
        <label class=" vs-input--label">Parent page(Optional)</label>
        <v-select
          :value="form_dropdown.parent_id"
          @input="updateDropdownForm({key: 'parent_id', value: $event})"
          :options="parent_pages"
          placeholder="Search and select"/>
      </div>
      <div class="vx-col w-full">
        <vs-input
          class="w-full mt-4"
          label="Page Name"
          :danger="!!validation.name"
          :danger-text="validation.name?validation.name[0]:''"
          :value="form.name"
          @input="updateForm({key: 'name', value: $event})" />
      </div>
      <div class="vx-col w-full">
        <vs-input
          class="w-full mt-4"
          label="Page Title"
          :danger="!!validation.title"
          :danger-text="validation.title?validation.title[0]:''"
          :value="form.title"
          @input="updateForm({key: 'title', value: $event})" />
      </div>
      <div class="vx-col w-full md:w-3/3">
        <vs-input
          class="w-full mt-4"
          label="Slug"
          :danger="!!validation.slug"
          :danger-text="validation.slug?validation.slug[0]:''"
          :value="form.slug"
          @input="updateForm({key: 'slug', value: $event})" />
      </div>

      <div class="vx-col w-full md:w-1/3">
         <vs-input
          class="w-full mt-4"
          label="Uri"
          :danger="!!validation.uri"
          :danger-text="validation.uri?validation.uri[0]:''"
          :value="form.uri"
          @input="updateForm({key: 'uri', value: $event})" />
      </div>


      <div class="vx-col w-full">
        <vs-input
          class="w-full mt-4"
          label="Excerpt"
          :danger="!!validation.excerpt"
          :danger-text="validation.excerpt?validation.excerpt[0]:''"
          :value="form.excerpt"
          @input="updateForm({key: 'excerpt', value: $event})" />
      </div>

      <div class="vx-col w-full mt-4">
        <label for="content">Content</label>
        <quill-editor
          ref="myQuillEditor"
          :options="editorOptions"
          :value="form.content? form.content: ''"
          @input="updateForm({key: 'content', value: $event})"></quill-editor>
        <span class="text-danger text-sm"
              v-show="!!validation.content">
        {{ validation.content?validation.content[0]:''}}
      </span>
      </div>
      <div class="vx-col w-full mt-4 ">
        <label class=" vs-input--label">Template</label>
        <v-select
          :value="form_dropdown.template"
          @input="updateForm({key: 'template', value: $event})"
          :options="template_types"
          placeholder="Search and select"/>
      </div>
  </div>
  </div>
</template>

<script>

import vSelect,{vOption} from 'vue-select'
import { mapGetters,mapActions } from 'vuex'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { Quill, quillEditor } from 'vue-quill-editor'
import ImageUploader from "quill-image-uploader/src/quill.imageUploader";
import { uploadContentImage } from "@/services/image";

Quill.register('modules/imageUploader', ImageUploader);
const fullToolbarOptions = [
  [{ header: [1, 2, 3, false] }],
  ["bold", "italic"],
  ["clean"],
  ["image"]
];

export default {
  data() {
    return {
      editorOptions: {
        modules: {
          toolbar: {
            container: fullToolbarOptions
          },
          // image uploader
          imageUploader: {
            // eslint-disable-next-line no-unused-vars
            upload: file => {
              // console.log(file);
              return new Promise((resolve, reject) => {
                uploadContentImage(
                  file
                )
                  .then(result => {
                    console.log(result);
                    resolve(result.data.url);
                  })
                  .catch(error => {
                    reject("Upload failed");
                    console.error("Error:", error);
                  });
              });
            },
          },
        },
      }
    }
  },
  mounted() {
    this.fetchTemplateTypes();
    this.fetchParentPages();
    let params = new URLSearchParams();
    params.append("query[type]", 'Page');
  },

  components: {
    vSelect,
    vOption,
    quillEditor
  },

  computed: {
    ...mapGetters({
        form: 'cms/getFormObj',
        form_dropdown: 'cms/getFormDropdownObj',
        validation: 'validation/getValidationErrors',
        template_types: 'template_type/getTemplateTypesDropdown',
        parent_pages: 'cms/getParentPagesDropdownObj',
    })
  },
  methods: {
    ...mapActions({
      updateForm: 'cms/updateForm',
      updateDropdownForm: 'cms/updateDropdownForm',
      fetchTemplateTypes: 'template_type/fetchAndSetTemplateTypesDropdown',
      fetchParentPages: 'cms/fetchAndSetParentPagesDropdown',
    }),
    handleMultipleSelect($event){
      this.updateForm($event);
    },
  },
}
</script>
